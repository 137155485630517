
.reactMarkDown > html {
  font-size: 100%;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

.reactMarkDown > body {
  color: #444;
  font-family: Georgia, Palatino, 'Palatino Linotype', Times, 'Times New Roman', serif;
  font-size: 12px;
  line-height: 1.5em;
  padding: 1em;
  margin: auto;
  max-width: 42em;
  background: #fefefe;
}

.reactMarkDown > a {
  color: #0645ad;
  text-decoration: none;
}

.reactMarkDown > a:visited {
  color: #0b0080;
}

.reactMarkDown > a:hover {
  color: #06e;
}

.reactMarkDown > a:active {
  color: #faa700;
}

.reactMarkDown > a:focus {
  outline: thin dotted;
}

.reactMarkDown > a:hover,
a:active {
  outline: 0;
}

.reactMarkDown > ::-moz-selection {
  background: rgba(255, 255, 0, 0.3);
  color: #000;
}

.reactMarkDown > ::selection {
  background: rgba(255, 255, 0, 0.3);
  color: #000;
}

.reactMarkDown > a::-moz-selection {
  background: rgba(255, 255, 0, 0.3);
  color: #0645ad;
}

.reactMarkDown > a::selection {
  background: rgba(255, 255, 0, 0.3);
  color: #0645ad;
}

.reactMarkDown > p {
  margin: 1em 0;
}

.reactMarkDown > img {
  max-width: 100%;
}

.reactMarkDown > h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  color: #111;
  line-height: 1em;
}

.reactMarkDown > h4,
h5,
h6 {
  font-weight: bold;
}

.reactMarkDown > h1 {
  font-size: 2.5em;
}

.reactMarkDown > h2 {
  font-size: 2em;
}

.reactMarkDown > h3 {
  font-size: 1.5em;
}

.reactMarkDown > h4 {
  font-size: 1.2em;
}

.reactMarkDown > h5 {
  font-size: 1em;
}

.reactMarkDown > h6 {
  font-size: 0.9em;
}

.reactMarkDown > blockquote {
  color: #666666;
  margin: 0;
  padding-left: 3em;
  border-left: 0.5em #eee solid;
}

.reactMarkDown > hr {
  display: block;
  border: 0;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #eee;
  margin: 1em 0;
  padding: 0;
}

.reactMarkDown > pre,
code,
kbd,
samp {
  color: #000;
  font-family: monospace, monospace;
  _font-family: 'courier new', monospace;
  font-size: 0.98em;
}

.reactMarkDown > pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.reactMarkDown > b,
strong {
  font-weight: bold;
}

.reactMarkDown > dfn {
  font-style: italic;
}

.reactMarkDown > ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}

.reactMarkDown > mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

.reactMarkDown > sub,
.reactMarkDown > sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.reactMarkDown > sup {
  top: -0.5em;
}

.reactMarkDown > sub {
  bottom: -0.25em;
}

.reactMarkDown > ul,
ol {
  margin: 1em 0;
  padding: 0 0 0 2em;
}

.reactMarkDown > li p:last-child {
  margin: 0;
}

.reactMarkDown > dd {
  margin: 0 0 0 2em;
}

.reactMarkDown > img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

.reactMarkDown > table {
  border-collapse: collapse;
  border-spacing: 0;
}

.reactMarkDown > td {
  vertical-align: top;
}

@media only screen and (min-width: 480px) {
  .reactMarkDown > body {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) {
  .reactMarkDown > body {
    font-size: 16px;
  }
}

@media print {
  .reactMarkDown > * {
    background: transparent !important;
    color: black !important;
    filter: none !important;
    -ms-filter: none !important;
  }

  .reactMarkDown > body {
    font-size: 12pt;
    max-width: 100%;
  }

  .reactMarkDown > a,
  a:visited {
    text-decoration: underline;
  }

  .reactMarkDown > hr {
    height: 1px;
    border: 0;
    border-bottom: 1px solid black;
  }

  .reactMarkDown > a[href]:after {
    content: " (" attr(href) ")";
  }

  .reactMarkDown > abbr[title]:after {
    content: " (" attr(title) ")";
  }

  .reactMarkDown > .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  .reactMarkDown > pre,
  blockquote {
    border: 1px solid #999;
    padding-right: 1em;
    page-break-inside: avoid;
  }

  .reactMarkDown > tr,
  img {
    page-break-inside: avoid;
  }

  .reactMarkDown > img {
    max-width: 100% !important;
  }

  .reactMarkDown > @page :left {
    margin: 15mm 20mm 15mm 10mm;
  }

  .reactMarkDown > @page :right {
    margin: 15mm 10mm 15mm 20mm;
  }

  .reactMarkDown > p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  .reactMarkDown > h2,
  h3 {
    page-break-after: avoid;
  }
}