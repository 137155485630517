 #wrapper {
    overflow-x: hidden;
 }

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}

.showallways{
  display: block !important;
}

.swagger-definition{
    padding: 0px;
    padding-left: 30px;
    padding-bottom: 5px;
    background-color: white;
    background: gray;
    padding-top: 5px;
    font-size: 0.8em;
}

.navbar{
  display: block;
}


@media only screen and (min-width: 600px) {
  .navbar{
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .navbar{
    display: none;
  }
}

.link-header{
  font-weight: bold;
}

/**/

@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";


body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}

#sidebar {
    transition: all 0.3s;
}

#sidebar .sidebar-header {
    padding: 20px;
}

#sidebar ul.components {
    padding: 20px 0;
}

#sidebar ul p {
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;


}
#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}

#sidebar-wrapper > ul > li {
    min-height: 40px;
    padding: 9px;
    background: gainsboro;
}

