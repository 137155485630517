.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 
  
.App {
  width: 100%;
}

.innerFrame{
  text-align: left;
    padding: 50px;
}

.navbar-brand {
    padding: 1rem 1rem;
    /* margin-left: auto; */
    /* margin-right: auto; */
    display: block;
}

img{
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
}

.notFound > p {
  font-size: 3em;
}

.page-wrap {
    min-height: 100vh;
}


.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.nested:hover {
  background-color: #ccc;
}

/* tree view */

nav ul, li {
  list-style-type: none;
  list-style: none;
}

nav li {
  margin: 0;
  padding: 0;
}

nav ul {
  background: #dfdfdf;
}

.caret {
  cursor: pointer;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.caret-down::before {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.nested {
  display: none;
  padding-left: 12px;
}

.active {
  display: block;
}
