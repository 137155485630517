@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

 
  
.App {
  width: 100%;
}

.innerFrame{
  text-align: left;
    padding: 50px;
}

.navbar-brand {
    padding: 1rem 1rem;
    /* margin-left: auto; */
    /* margin-right: auto; */
    display: block;
}

img{
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-top: auto;
    margin-bottom: auto;
}

.notFound > p {
  font-size: 3em;
}

.page-wrap {
    min-height: 100vh;
}


.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.nested:hover {
  background-color: #ccc;
}

/* tree view */

nav ul, li {
  list-style-type: none;
  list-style: none;
}

nav li {
  margin: 0;
  padding: 0;
}

nav ul {
  background: #dfdfdf;
}

.caret {
  cursor: pointer;
  -webkit-user-select: none; /* Safari 3.1+ */ /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.caret::before {
  content: "\25B6";
  color: black;
  display: inline-block;
  margin-right: 6px;
}

.caret-down::before {
  transform: rotate(90deg);
}

.nested {
  display: none;
  padding-left: 12px;
}

.active {
  display: block;
}

 #wrapper {
    overflow-x: hidden;
 }

#sidebar-wrapper {
  min-height: 100vh;
  margin-left: -15rem;
  transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  width: 15rem;
}

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}

.showallways{
  display: block !important;
}

.swagger-definition{
    padding: 0px;
    padding-left: 30px;
    padding-bottom: 5px;
    background-color: white;
    background: gray;
    padding-top: 5px;
    font-size: 0.8em;
}

.navbar{
  display: block;
}


@media only screen and (min-width: 600px) {
  .navbar{
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .navbar{
    display: none;
  }
}

.link-header{
  font-weight: bold;
}

/**/


body {
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}

#sidebar {
    transition: all 0.3s;
}

#sidebar .sidebar-header {
    padding: 20px;
}

#sidebar ul.components {
    padding: 20px 0;
}

#sidebar ul p {
    padding: 10px;
}

#sidebar ul li a {
    padding: 10px;
    font-size: 1.1em;
    display: block;


}
#sidebar ul li a:hover {
    color: #7386D5;
    background: #fff;
}

#sidebar-wrapper > ul > li {
    min-height: 40px;
    padding: 9px;
    background: gainsboro;
}



.reactMarkDown > html {
  font-size: 100%;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

.reactMarkDown > body {
  color: #444;
  font-family: Georgia, Palatino, 'Palatino Linotype', Times, 'Times New Roman', serif;
  font-size: 12px;
  line-height: 1.5em;
  padding: 1em;
  margin: auto;
  max-width: 42em;
  background: #fefefe;
}

.reactMarkDown > a {
  color: #0645ad;
  text-decoration: none;
}

.reactMarkDown > a:visited {
  color: #0b0080;
}

.reactMarkDown > a:hover {
  color: #06e;
}

.reactMarkDown > a:active {
  color: #faa700;
}

.reactMarkDown > a:focus {
  outline: thin dotted;
}

.reactMarkDown > a:hover,
a:active {
  outline: 0;
}

.reactMarkDown > ::selection {
  background: rgba(255, 255, 0, 0.3);
  color: #000;
}

.reactMarkDown > a::selection {
  background: rgba(255, 255, 0, 0.3);
  color: #0645ad;
}

.reactMarkDown > p {
  margin: 1em 0;
}

.reactMarkDown > img {
  max-width: 100%;
}

.reactMarkDown > h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  color: #111;
  line-height: 1em;
}

.reactMarkDown > h4,
h5,
h6 {
  font-weight: bold;
}

.reactMarkDown > h1 {
  font-size: 2.5em;
}

.reactMarkDown > h2 {
  font-size: 2em;
}

.reactMarkDown > h3 {
  font-size: 1.5em;
}

.reactMarkDown > h4 {
  font-size: 1.2em;
}

.reactMarkDown > h5 {
  font-size: 1em;
}

.reactMarkDown > h6 {
  font-size: 0.9em;
}

.reactMarkDown > blockquote {
  color: #666666;
  margin: 0;
  padding-left: 3em;
  border-left: 0.5em #eee solid;
}

.reactMarkDown > hr {
  display: block;
  border: 0;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #eee;
  margin: 1em 0;
  padding: 0;
}

.reactMarkDown > pre,
code,
kbd,
samp {
  color: #000;
  font-family: monospace, monospace;
  _font-family: 'courier new', monospace;
  font-size: 0.98em;
}

.reactMarkDown > pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.reactMarkDown > b,
strong {
  font-weight: bold;
}

.reactMarkDown > dfn {
  font-style: italic;
}

.reactMarkDown > ins {
  background: #ff9;
  color: #000;
  text-decoration: none;
}

.reactMarkDown > mark {
  background: #ff0;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

.reactMarkDown > sub,
.reactMarkDown > sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

.reactMarkDown > sup {
  top: -0.5em;
}

.reactMarkDown > sub {
  bottom: -0.25em;
}

.reactMarkDown > ul,
ol {
  margin: 1em 0;
  padding: 0 0 0 2em;
}

.reactMarkDown > li p:last-child {
  margin: 0;
}

.reactMarkDown > dd {
  margin: 0 0 0 2em;
}

.reactMarkDown > img {
  border: 0;
  -ms-interpolation-mode: bicubic;
  vertical-align: middle;
}

.reactMarkDown > table {
  border-collapse: collapse;
  border-spacing: 0;
}

.reactMarkDown > td {
  vertical-align: top;
}

@media only screen and (min-width: 480px) {
  .reactMarkDown > body {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) {
  .reactMarkDown > body {
    font-size: 16px;
  }
}

@media print {
  .reactMarkDown > * {
    background: transparent !important;
    color: black !important;
    -webkit-filter: none !important;
            filter: none !important;
    -ms-filter: none !important;
  }

  .reactMarkDown > body {
    font-size: 12pt;
    max-width: 100%;
  }

  .reactMarkDown > a,
  a:visited {
    text-decoration: underline;
  }

  .reactMarkDown > hr {
    height: 1px;
    border: 0;
    border-bottom: 1px solid black;
  }

  .reactMarkDown > a[href]:after {
    content: " (" attr(href) ")";
  }

  .reactMarkDown > abbr[title]:after {
    content: " (" attr(title) ")";
  }

  .reactMarkDown > .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  .reactMarkDown > pre,
  blockquote {
    border: 1px solid #999;
    padding-right: 1em;
    page-break-inside: avoid;
  }

  .reactMarkDown > tr,
  img {
    page-break-inside: avoid;
  }

  .reactMarkDown > img {
    max-width: 100% !important;
  }

  .reactMarkDown > @page :left {
    margin: 15mm 20mm 15mm 10mm;
  }

  .reactMarkDown > @page :right {
    margin: 15mm 10mm 15mm 20mm;
  }

  .reactMarkDown > p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  .reactMarkDown > h2,
  h3 {
    page-break-after: avoid;
  }
}
